import Provider from '@/provider'
import {
  Attribute,
  CartAttributesUpdateInput,
  CartDiscountCodeApplyInput,
  CartDiscountCodeRemoveInput,
  CartLineItemsRemoveInput,
  CartLineItemsUpdateInput,
  CartLineItemUpdateInput,
} from '@/provider/type'
import { CartLineItemInput } from '@/provider/type'
import Analytics from '@/services/analytics'
import CacheService from '@/services/cache'
import { CartLineItem, InputWithTimeStamp, NullOrType } from '@/types'
import {
  CACHE_KEY_CART_ID,
  COMPARE_AT_PRICE_LINE_ITEM_ATTRIBUTE_KEY,
  DISCOUNT_MESSAGE_LINE_ITEM_ATTRIBUTE_KEY,
  DOMAIN_ATTRIBUTE_KEY,
  PRICE_LINE_ITEM_ATTRIBUT_KEY,
  USER_TRAITS_ATTRIBUTE_KEY,
  UTMS_ATTRIBUTE_KEY,
  FIRST_PAGE_KEY,
  COOKIE_KEY_ATTRIBUTE_UTM_PREFIX,
  ONE_YEAR_IN_SECONDS,
  PRODUCT_TAG_DISCOUNT_DEFAULT,
  CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY,
  CAMPAIGN_DISCOUNT_BLACKFRIDAY9,
  PRODUCT_TAG_PROMOTION_NO,
  PRODUCT_PACK,
} from '@/utils'

import {
  extractProductSKUFromHandle,
  getTruncatedVariantSKU,
  getTruncatedVariantSKUWithProductHandlle,
} from '@/utils/product'
import { isOnServer } from '@/utils/ssr'
import { defineStore } from 'pinia'
import { CookieManager } from '@/services/cookie'
import useSettingStore from '../setting'

export interface CartState {
  cart: NullOrType<any>
  localCartLineItems: any[]
  lastUpdateTimeStamp: number
  recoveryCartId: NullOrType<string>
  shouldRecoveryCartIdBaseOnEmailKlaviyo: boolean
  recoverCartIdByEmailMessage: string
}

const useCartStore = defineStore('cart', {
  state: (): CartState => ({
    cart: null,
    localCartLineItems: [],
    lastUpdateTimeStamp: 0,
    recoveryCartId: null,
    shouldRecoveryCartIdBaseOnEmailKlaviyo: false,
    recoverCartIdByEmailMessage: '',
  }),
  getters: {
    subTotal: (state) => {
      return state.cart?.estimatedCost?.subtotalAmount?.amount || 0
    },
    totalLineItemsPrice: (state) => {
      let total = 0
      state.cart?.lines.forEach((item) => {
        total += item.quantity * (item.compareAtPrice || item.price || 0)
      })
      return total
    },
    totalDiscount(): number {
      return this.totalLineItemsPrice - this.subTotal
    },
    totalCartItems: (state) => {
      return state.localCartLineItems.reduce(
        (acc, item) => acc + item.quantity,
        0
      )
    },
    discountCodeCurrentApply(state) {
      let discountCode = ''
      if (state?.cart?.discountCodes.length) {
        const discountCodeApplied = state?.cart?.discountCodes.find(
          (discountCode) => discountCode.applicable
        )
        if (discountCodeApplied) discountCode = discountCodeApplied.code
      }
      return discountCode
    },
    customerNumberOfOrdered(state) {
      return state?.cart?.buyerIdentity?.customer?.numberOfOrders || 0
    },
  },
  actions: {
    async getCurrentCart() {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      let cartId = ''
      if (this.recoveryCartId) {
        cartId = this.recoveryCartId
        this.recoveryCartId = null
      } else if (this.cart) {
        cartId = this.cart.id
      } else {
        const cookier = new CookieManager()
        const cartIdFromCookie = cookier.getCookie(CACHE_KEY_CART_ID)
        if (cartIdFromCookie) {
          cartId = cartIdFromCookie
        } else {
          const cartIdFromCache = await CacheService.instance?.get(
            CACHE_KEY_CART_ID
          )
          if (cartIdFromCache && cartIdFromCache.value) {
            carttId = cartIdFromCache.value
            const cookier = new CookieManager()

            const href = new URL(window.location.href)
            const currentDomain = href.hostname
            cookier.setCookie(CACHE_KEY_CART_ID, cartId, {
              maxage: ONE_YEAR_IN_SECONDS,
              domain: '.' + currentDomain,
              path: '/',
            })
          }
        }
      }
      if (cartId) {
        return provider.getCart(cartId)
      }
      return null
    },
    async createCart(input: any) {
      if (isOnServer) return
      input.attributes = await fillUpCartAttributes(input.attributes)
      const provider = await Provider.getInstance()
      return provider.createCart(input)
    },

    async saveCartAndLines(cart: NullOrType<Cart>) {
      if (isOnServer) return
      this.saveCart(JSON.parse(JSON.stringify(cart)))
      this.localCartLineItems = JSON.parse(JSON.stringify(cart?.lines || []))
      this.localCartLineItems = this.localCartLineItems?.map(fillUpLineItem)
    },

    async saveCart(cart: NullOrType<Cart>) {
      if (isOnServer) return
      this.cart = cart
      if (!this.cart) return
      this.cart.lines = this.cart.lines?.map(fillUpLineItem)
      const cartId = cart?.id
      if (cartId) {
        // cache cart forever
        CacheService.instance?.set(CACHE_KEY_CART_ID, cartId, -1)

        // cache Cart id in cookie fallback brower doesn't support IndexDB and sync cart online store with shopify theme
        const href = new URL(window.location.href)
        const currentDomain = href.hostname
        const cookier = new CookieManager()
        cookier.setCookie(CACHE_KEY_CART_ID, cartId, {
          maxage: ONE_YEAR_IN_SECONDS,
          domain: '.' + currentDomain,
          path: '/',
        })
      } else {
        CacheService.instance?.delete(CACHE_KEY_CART_ID)
      }
    },
    async saveCartWithoutUpdateLineItemsQuantity(cart: NullOrType<Cart>) {
      if (isOnServer) return
      if (!cart) return
      this.saveCart(cart)

      // use the lineItems from the new cart but reserve the quantity from the old cart
      let lines = JSON.parse(JSON.stringify(cart.lines || []))
      lines = lines.map((line: CartLineItem) => {
        const oldLine = this.localCartLineItems.find(
          (item) => item.id === line.id
        )
        if (oldLine) {
          line.quantity = oldLine.quantity
        }
        line = fillUpLineItem(line)
        return line
      })
      this.localCartLineItems = lines
    },
    async addItemToCart(input: CartLineItemsAddInput) {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      return provider.cartLinesAdd(input)
    },
    async removeItemFromCart(input: CartLineItemsRemoveInput) {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      return provider.cartLinesRemove(input)
    },

    async updateItemsInCart(input: CartLineItemsUpdateInput) {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      return provider.cartLinesUpdate(input)
    },

    async updateCartAttributes(input: CartAttributesUpdateInput) {
      if (isOnServer) return
      const provider = await Provider.getInstance()
      return provider.cartAttributesUpdate(input)
    },

    async cartDiscountCodeApply(discountCode: string) {
      if (isOnServer) return
      const cartId = this.cart?.id
      if (!cartId) return
      const provider = await Provider.getInstance()
      const input: CartDiscountCodeApplyInput = {
        cartId,
        discountCodes: [discountCode],
      }
      const cart = await provider.cartDiscountCodesUpdate(input)
      this.saveCartAndLines(cart)
      return cart
    },

    async cartDiscountCodeRemove() {
      if (isOnServer) return
      const cartId = this.cart?.id
      if (!cartId) return
      const provider = await Provider.getInstance()
      const input: CartDiscountCodeRemoveInput = {
        cartId,
        discountCodes: [],
      }
      const cart = await provider.cartDiscountCodesUpdate(input)
      this.saveCartAndLines(cart)
    },

    async updateCartBuyerIdentity(input: {
      buyerIdentity: MailingAddressInput
      cartId: string
    }) {
      if (isOnServer) return
      if (!input.cartId || !input.buyerIdentity) return
      const provider = await Provider.getInstance()
      return provider.cartBuyerIdentityUpdate(input)
    },

    async updateCartEmail(input: { cartId: string; email: string }) {
      if (isOnServer) return
      if (!input.cartId || !input.email) return
      const provider = await Provider.getInstance()
      return provider.cartEmailUpdate(input)
    },

    async addToCart(line: CartLineItemInput) {
      if (isOnServer) return
      if (!line.merchandiseId || !line.quantity) return
      line = cleanAttributesLineItem(line)
      if (!this.cart) {
        const cart = await this.createCart({
          lines: [line],
        })
        this.saveCartAndLines(cart)
        return cart
      }

      const cart = await this.addItemToCart({
        cartId: this.cart.id,
        lines: [line],
      })
      this.saveCartAndLines(cart)
      return cart
    },

    async removeFromCart(
      input: InputWithTimeStamp<{
        lineId: string
      }>
    ) {
      if (isOnServer) return
      this.lastUpdateTimeStamp = input.timeStamp
      if (!this.cart || !input.lineId) return
      const cart = await this.removeItemFromCart({
        cartId: this.cart.id,
        lineIds: [input.lineId],
      })
      // only save the cart of the lastest request
      if (this.lastUpdateTimeStamp !== input.timeStamp) return null
      this.saveCartAndLines(cart)
      return cart
    },

    async updateCartLine(
      input: InputWithTimeStamp<{
        id: string
        quantity: number
      }>
    ) {
      if (isOnServer) return
      this.lastUpdateTimeStamp = input.timeStamp
      if (!this.cart || !input.id) return
      const cart = await this.updateItemsInCart({
        cartId: this.cart.id,
        lines: [
          {
            id: input.id,
            quantity: input.quantity,
          },
        ],
      })
      // only save the cart of the lastest request
      if (this.lastUpdateTimeStamp === input.timeStamp) {
        this.saveCartAndLines(cart)
        return cart
      }
      return null
    },

    async updateAttributeCartLine(
      input: InputWithTimeStamp<{
        id: string
        attributes: { key: string; value: string }[]
      }>
    ) {
      if (isOnServer) return
      this.lastUpdateTimeStamp = input.timeStamp
      if (!this.cart || !input.id) return
      if (attributes?.length) {
        attributes = attributes?.filter((attribute) => attribute.value)
      }
      const cart = await this.updateItemsInCart({
        cartId: this.cart.id,
        lines: [
          {
            id: input.id,
            attributes: input.attributes,
          },
        ],
      })
      // only save the cart of the lastest request
      if (this.lastUpdateTimeStamp === input.timeStamp) {
        this.saveCartAndLines(cart)
        return cart
      }
      return null
    },

    async updateCartLines(
      input: InputWithTimeStamp<{
        lines: CartLineItemUpdateInput[]
      }>
    ) {
      if (isOnServer) return
      this.lastUpdateTimeStamp = input.timeStamp
      if (!this.cart) return
      const lines = input.lines?.map((line) => cleanAttributesLineItem(line))
      const cart = await this.updateItemsInCart({
        cartId: this.cart.id,
        lines: lines,
      })
      // only save the cart of the lastest request
      if (this.lastUpdateTimeStamp === input.timeStamp) {
        this.saveCartWithoutUpdateLineItemsQuantity(cart)
        return cart
      }
      return null
    },
  },
})

export async function fillUpCartAttributes(attributesInput?: Attribute[]) {
  const attributes: Attribute[] = []
  if (attributesInput) {
    attributesInput.forEach((attribute) => {
      attributes.push({
        key: attribute.key,
        value: attribute.value,
      })
    })
  }
  const domainAttributeIndex = attributes.findIndex(
    (attribute) => attribute.key == DOMAIN_ATTRIBUTE_KEY
  )
  if (domainAttributeIndex === -1) {
    attributes.push({
      key: DOMAIN_ATTRIBUTE_KEY,
      value: window.location.origin,
    })
  }

  const userTraitsAttribute = Analytics.getUserTraits()

  if (userTraitsAttribute) {
    const userTraitsAttributeIndex = attributes.findIndex(
      (attribute) => attribute.key == USER_TRAITS_ATTRIBUTE_KEY
    )
    if (userTraitsAttributeIndex > -1) {
      attributes.splice(userTraitsAttributeIndex, 1)
    }
    attributes.push({
      key: USER_TRAITS_ATTRIBUTE_KEY,
      value: JSON.stringify(userTraitsAttribute),
    })
  }

  const firstPageFromCookie = Analytics.getCookieFirstPage()
  if (firstPageFromCookie) {
    const firstPageAttributeIndex = attributes.findIndex(
      (attribute) => attribute.key == FIRST_PAGE_KEY
    )
    if (firstPageAttributeIndex > -1) {
      attributes.splice(firstPageAttributeIndex, 1)
    }
    attributes.push({
      key: FIRST_PAGE_KEY,
      value: firstPageFromCookie,
    })
  }

  const currentUserUtms = await Analytics.getCurrentUTMs()
  if (currentUserUtms) {
    const utmsAttributeIndex = attributes.findIndex(
      (attribute) => attribute.key == UTMS_ATTRIBUTE_KEY
    )
    if (utmsAttributeIndex > -1) {
      attributes.splice(utmsAttributeIndex, 1)
    }
    attributes.push({
      key: UTMS_ATTRIBUTE_KEY,
      value: JSON.stringify(currentUserUtms),
    })
  }

  const cookieUserUtms = Analytics.getCookieCurrentUTMs()
  if (cookieUserUtms) {
    const UTM_PARAM_PREFIX = 'utm_'
    Object.keys(cookieUserUtms).forEach((key) => {
      const utmAttributeKey = `${COOKIE_KEY_ATTRIBUTE_UTM_PREFIX}${UTM_PARAM_PREFIX}${key}`
      const utmsAttributeIndex = attributes.findIndex(
        (attribute) => attribute.key == utmAttributeKey
      )
      if (utmsAttributeIndex > -1) {
        attributes.splice(utmsAttributeIndex, 1)
      }
      attributes.push({
        key: utmAttributeKey,
        value: cookieUserUtms[key],
      })
    })
  }
  return attributes
}

export function buildDiscountMessageLineItem(
  line: CartLineItem,
  cartLineItems: any[],
  discountCodeCurrentApply: string
) {
  if (!line || !cartLineItems.length) return ''

  if (discountCodeCurrentApply) return `Discount`

  const isPackProduct = (line) =>
    line.attributes.some(
      (item) => item?.key == PRODUCT_PACK && item?.value == 'true'
    )

  const packLines = cartLineItems.filter((line) => isPackProduct(line))
  const noPackLines = cartLineItems.filter((line) => !isPackProduct(line))
  if (isPackProduct(line) && packLines.length) {
    const DEFAULT_ORNAMENT_PRODUCT_QUANTITY_TIERS = {
      tiers: [
        {
          quantity: 1,
          value: 'Pack 1',
        },
        {
          quantity: 2,
          value: 'Pack 2',
        },
        {
          quantity: 3,
          value: 'Pack 3',
        },
        {
          quantity: 4,
          value: 'Pack 4',
        },
        {
          quantity: 5,
          value: 'Pack 5',
        },
        {
          quantity: 10,
          value: 'Pack 10',
        },
      ],
    }
    const packLinesGroup = packLines.reduce((acc, item) => {
      const productId = item.merchandise.product.id.toString()
      if (!acc[productId]) acc[productId] = []
      acc[productId].push({
        item,
      })
      return acc
    }, {})
    const discountPackProductMessages = []
    Object.entries(packLinesGroup).forEach(([key, lines]) => {
      let totalApplicableQuantity = 0
      let discountPackProductMessage = ''
      // Calculate total quantity applied based on pack type
      lines.forEach((line) => {
        const title = line.item.merchandise?.title?.toLowerCase()
        if (title.includes('pack 1')) {
          totalApplicableQuantity += line.item.quantity * 1
        } else if (title.includes('pack 10')) {
          totalApplicableQuantity += line.item.quantity * 10
        } else if (title.includes('pack 5')) {
          totalApplicableQuantity += line.item.quantity * 5
        } else if (title.includes('pack 4')) {
          totalApplicableQuantity += line.item.quantity * 4
        } else if (title.includes('pack 3')) {
          totalApplicableQuantity += line.item.quantity * 3
        } else if (title.includes('pack 2')) {
          totalApplicableQuantity += line.item.quantity * 2
        }
      })
      const totalQuantity = totalApplicableQuantity

      const fisrtLine = lines[0]?.item
      const packNames = []
      fisrtLine.attributes.forEach((attribute) => {
        const keyLower = attribute.key.toLowerCase()
        if (keyLower.includes('pack 10')) {
          packNames.push('pack 10')
        } else if (keyLower.includes('pack 5')) {
          packNames.push('pack 5')
        } else if (keyLower.includes('pack 4')) {
          packNames.push('pack 4')
        } else if (keyLower.includes('pack 3')) {
          packNames.push('pack 3')
        } else if (keyLower.includes('pack 2')) {
          packNames.push('pack 2')
        } else if (keyLower.includes('pack 1')) {
          packNames.push('pack 1')
        }
      })

      // Calculate shipping costs based on tiers
      while (totalApplicableQuantity > 0) {
        const tiers = DEFAULT_ORNAMENT_PRODUCT_QUANTITY_TIERS.tiers.sort(
          (a, b) => b.quantity - a.quantity
        ) // Sort tier by quantity descending
        const applicableTier = tiers.find(
          (tier) =>
            tier.quantity <= totalApplicableQuantity &&
            packNames.includes(tier.value.toLowerCase())
        )
        if (!applicableTier) break
        if (totalQuantity > 1) {
          discountPackProductMessage =
            discountPackProductMessage +
            ` Ornament buy more save more of ${applicableTier.value}.`
        }
        totalApplicableQuantity -= applicableTier.quantity
      }
      discountPackProductMessages.push({
        productId: key,
        message: discountPackProductMessage,
      })
    })
    if (discountPackProductMessages.length) {
      const lineDiscountMessage = discountPackProductMessages.find(
        (item) => item.productId == line.merchandise?.product?.id
      )
      return lineDiscountMessage?.message
    }
  } else if (!isPackProduct(line) && noPackLines.length) {
    const isPromotionNoTag = line.merchandise.product.tags.includes(
      PRODUCT_TAG_PROMOTION_NO
    )
    if (isPromotionNoTag) return ''

    const bf9CampaignAttribute = line.attributes.find((attribute) => {
      return (
        attribute.key === CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY &&
        attribute.value == CAMPAIGN_DISCOUNT_BLACKFRIDAY9
      )
    })
    if (bf9CampaignAttribute) return 'Sale off'

    // caculate to check discount for return customer base on percent discounted
    let lineSubtotalAmount = line.cost?.subtotalAmount?.amount || 0
    const discountDefaultTag = line.merchandise?.product?.tags?.find((tag) =>
      tag.includes(PRODUCT_TAG_DISCOUNT_DEFAULT)
    )
    const valueDiscount = discountDefaultTag?.split('_').at(-1)
    if (valueDiscount) {
      lineSubtotalAmount =
        line.cost?.subtotalAmount?.amount * ((100 - valueDiscount / 10) / 100)
    }
    const percentDiscountApplied =
      100 - (line.cost?.totalAmount?.amount / lineSubtotalAmount) * 100
    const PERCENTAGE_DISCOUNT_RETURN_CUSTOMER = 20
    const isDiscountReturnCustomer =
      percentDiscountApplied.toFixed() == PERCENTAGE_DISCOUNT_RETURN_CUSTOMER
    if (isDiscountReturnCustomer) return '20% off for return customer'

    let cartLineQuantity = 0
    for (let i = 0; i < noPackLines.length; i++) {
      cartLineQuantity = cartLineQuantity + noPackLines[i].quantity
    }
    if (cartLineQuantity >= 4) {
      return '15% off for ≥ 4 items'
    } else if (cartLineQuantity == 3) {
      return '10% off for 3 items'
    } else if (cartLineQuantity == 2) {
      return '5% off for 2 items'
    }
  }
  return ''
}

function fillUpLineItem(line: CartLineItem) {
  const priceAttribute = line.attributes.find(
    (elm) => elm.key == PRICE_LINE_ITEM_ATTRIBUT_KEY
  )
  const isPriceAttributeValid =
    priceAttribute && priceAttribute.value && !isNaN(+priceAttribute.value)
  const priceFromAttribute = isPriceAttributeValid ? +priceAttribute.value : 0

  const compareAtPriceAttribute = line.attributes.find(
    (elm) => elm.key == COMPARE_AT_PRICE_LINE_ITEM_ATTRIBUTE_KEY
  )
  const isCompareAtPriceAttributeValid =
    compareAtPriceAttribute &&
    compareAtPriceAttribute.value &&
    !isNaN(+compareAtPriceAttribute.value)
  const compareAtPriceFromAttribute = isCompareAtPriceAttributeValid
    ? +compareAtPriceAttribute.value
    : 0

  //  discount when line item has discount default tag
  const discountDefaultTag = line.merchandise?.product?.tags?.find((tag) =>
    tag.includes(PRODUCT_TAG_DISCOUNT_DEFAULT)
  )
  const valueDiscount = discountDefaultTag?.split('_').at(-1)
  if (discountDefaultTag && valueDiscount) {
    line.merchandise.priceV2.amount =
      discountDefaultTag && valueDiscount
        ? (
            +line.merchandise.priceV2.amount *
            ((100 - valueDiscount / 10) / 100)
          )?.toFixed(2)
        : +line.merchandise.priceV2.amount
  }
  // end discount when line item has discount default tag

  const discountMessageAttribute = line.attributes.find(
    (elm) => elm.key == DISCOUNT_MESSAGE_LINE_ITEM_ATTRIBUTE_KEY
  )

  line.price = isPriceAttributeValid
    ? priceFromAttribute
    : line.merchandise?.priceV2?.amount || 0
  line.compareAtPrice = isCompareAtPriceAttributeValid
    ? compareAtPriceFromAttribute
    : line.merchandise?.compareAtPriceV2?.amount || 0

  if (discountMessageAttribute) {
    line.discountMessage = discountMessageAttribute.value
  }

  if (line.merchandise && line.merchandise.sku) {
    const settingStore = useSettingStore()
    let hasProductHandle = false
    if (line.merchandise.product?.handle) {
      const skuFromHandle = extractProductSKUFromHandle(
        line.merchandise.product?.handle
      )
      hasProductHandle =
        settingStore.shop?.googleProductSKUsFromHandle
          ?.split(',')
          .includes(skuFromHandle) || false
    }
    line.merchandise.sku = hasProductHandle
      ? getTruncatedVariantSKU(line.merchandise.sku)
      : getTruncatedVariantSKUWithProductHandlle(
          line.merchandise.sku,
          line.merchandise.product?.handle
        )
  }

  // Analytics error if variant is null to track this bug
  if (!line.merchandise) {
    try {
      throw line.merchandise?.priceV2?.amount
    } catch (err: any) {
      Analytics.error(err)
    }
  }

  return line
}

function cleanAttributesLineItem(line: CartLineItem) {
  if (!line?.attributes?.length) return line
  line.attributes = line.attributes?.filter((attribute) => attribute.value)
  return line
}

export default useCartStore
