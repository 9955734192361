<template>
  <div class="cart">
    <CartItems
      @link-clicked="$emit('link-clicked')"
      @cart-recovery="recoverCartByEmail"
      v-model:loading="loading"
    />
    <CartPriceContainer />
    <CartProductRecommendation />
    <CartRecentlyViewedItems
      v-if="cartStore.cart && !cartStore.totalCartItems"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, h, onMounted, ref, watch } from 'vue'
import CartPrice from './CartPrice.vue'
import useDeviceStore from '@/store/device'
import CartItems from './CartItems.vue'
import { useFetchData } from '@/composables/fetchData'
import useCartStore, { fillUpCartAttributes } from '@/store/cart'
import useCheckoutStore from '@/store/checkout'
import CacheService from '@/services/cache'
import { CookieManager } from '@/services/cookie'
import {
  CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY,
  getCurrentTimeStamp,
  UTMS_ATTRIBUTE_KEY,
  TIME_MARK_CART_START_LOADING,
  EVENT_SAVE_DISCOUNT_CODE_TO_CACHE,
  CACHE_KEY_CHECKOUT_ID,
  CAMPAIGN_DISCOUNT_BLACKFRIDAY9,
  DISCOUNT_CALCULATION_ATTRIBUTE_KEY,
  PRODUCT_PACK,
  PRICE_LINE_ITEM_ATTRIBUT_KEY,
  COMPARE_AT_PRICE_LINE_ITEM_ATTRIBUTE_KEY,
  PERCENTAGE_DISCOUNT_BF9_FOR_PACK_OPTION_PERCENTAGE,
} from '@/utils'
import useDiscountStore from '@/store/discount'
import { CartLineItemUpdateInput } from '@/provider/type'
import Analytics from '@/services/analytics'
import useRouteStore from '@/store/route'
import { saveDiscountCodeToCache } from '@/utils/discount'
import EventBus from '@/services/eventbus'
import useProductStore from '@/store/product'

const CartRecentlyViewedItems = defineAsyncComponent(
  () => import('./CartRecentlyViewedItems.vue')
)
const CartProductRecommendation = defineAsyncComponent(
  () => import('./CartProductRecommendation.vue')
)

const productStore = useProductStore()
const routeStore = useRouteStore()

const props = withDefaults(
  defineProps<{
    wrapper: HTMLElement | null
    isUseWrapper: boolean
    isFullPage: boolean
  }>(),
  {}
)

const emit = defineEmits<{
  (e: 'link-clicked'): void
}>()

performance.mark(TIME_MARK_CART_START_LOADING)

const newProps = computed(() => {
  return {
    wrapper: props.wrapper,
    isUseWrapper: props.isUseWrapper,
    isFullPage: props.isFullPage,
    itemLoading: loading.value,
    onLinkClicked() {
      emit('link-clicked')
    },
  }
})

const cartTTL = 10 * 60 * 1000 // 10 minutes
const lastTimeFetchCart = ref(getCurrentTimeStamp())
const cartStore = useCartStore()
const checkoutStore = useCheckoutStore()
const discountStore = useDiscountStore()
const { fetchData, loading } = useFetchData({
  fetchDataFunction: fetchCart,
})

onMounted(async () => {
  await fetchData()
  applyDiscount()

  // Update cart attribute after a time out to prevent storefront API rate limit
  setTimeout(() => {
    Analytics.excuteOnReady(async () => {
      if (!cartStore.cart) return
      const currentCartAttributes = cartStore.cart.attributes
      const newCartAttributes = await fillUpCartAttributes(
        currentCartAttributes
      )
      // Only update cart attributes when utms changed or key added
      if (currentCartAttributes?.length === newCartAttributes.length) {
        const currentUtms = currentCartAttributes?.find(
          (attr) => attr.key === UTMS_ATTRIBUTE_KEY
        )
        const newUtms = newCartAttributes?.find(
          (attr) => attr.key === UTMS_ATTRIBUTE_KEY
        )
        if (currentUtms?.value === newUtms?.value) return
      }

      cartStore.updateCartAttributes({
        attributes: newCartAttributes,
        cartId: cartStore.cart?.id,
      })
    })
  }, 200)

  // recover cart when user visit site from klaviyo and has cartId
  watch(
    () => cartStore.shouldRecoveryCartIdBaseOnEmailKlaviyo,
    async () => {
      if (cartStore.shouldRecoveryCartIdBaseOnEmailKlaviyo) {
        cartStore.cart = null
        await fetchData()
      }
    }
  )
  watch(
    () => [
      cartStore.totalCartItems,
      productStore.productRecommendation?.moreItems?.length,
      productStore.productRecommendationShopify?.length,
    ],
    async ([value, productRecommendation, productRecommendationShopify]) => {
      const priceContainer = document.querySelector('.cart__price-container')
      if (!priceContainer) return
      if (value && !productRecommendation && !productRecommendationShopify) {
        priceContainer.classList.remove('cart__price-container-no-sticky')
      } else if (
        !value ||
        productRecommendation ||
        productRecommendationShopify
      ) {
        priceContainer.classList.add('cart__price-container-no-sticky')
      }
    },
    {
      immediate: true,
    }
  )
})

async function recoverCartByEmail() {
  cartStore.cart = null
  await fetchData()
}

async function fetchCart() {
  let cart = await cartStore.getCurrentCart()
  if (!isCheckCartValid(cart)) {
    cart = await cartStore.createCart({
      lines: [],
    })
  }
  cartStore.saveCartAndLines(cart)

  migrateDataCheckoutToCart()
}

async function migrateDataCheckoutToCart() {
  try {
    const checkout = await checkoutStore.getCurrentCheckout()

    if (checkout.lineItems.length && !cartStore.cart.lines.length) {
      for (let i = 0; i < checkout.lineItems.length; i++) {
        const lineItem = {
          merchandiseId: checkout.lineItems[i].variant.id,
          quantity: checkout.lineItems[i].quantity,
          attributes: checkout.lineItems[i].customAttributes.map((item) => ({
            key: item.key,
            value: item.value,
          })),
        }
        await cartStore.addToCart(lineItem)
      }
    }

    CacheService.instance?.delete(CACHE_KEY_CHECKOUT_ID)
    const cookier = new CookieManager()
    cookier.deleteCookie(CACHE_KEY_CHECKOUT_ID, {
      domain: '.' + routeStore.currentDomain,
    })
  } catch (error) {}
}

function isCheckCartValid(cart: any) {
  if (!cart || cart.completedAt) {
    return false
  }
  if (cart.lines) {
    for (let i = 0; i < cart.lines.length; i++) {
      const lineItem = cart.lines[i]
      if (!lineItem.merchandise) {
        return false
      }
    }
  }
  return true
}

function checkRefreshCart() {
  const currentTimeStamp = getCurrentTimeStamp()
  if (currentTimeStamp - lastTimeFetchCart.value > cartTTL) {
    fetchData()
  }
}

function mappingCartLineToProduct(line: any) {
  return {
    handle: line.merchandise?.product?.handle,
    title: line.merchandise?.product?.title,
    priceV2: line.merchandise?.priceV2,
    compareAtPriceV2: line.merchandise?.compareAtPriceV2,
    productType: line.merchandise?.product?.productType,
    tags: line.merchandise?.product?.tags || '',
    sku: line.merchandise?.product?.sku,
  }
}

async function applyDiscount() {
  if (!cartStore.cart) return

  // check for update discount campaign
  const lineItemsToUpdate: CartLineItemUpdateInput[] = []
  cartStore.localCartLineItems.forEach((lineItem) => {
    let attributes = [...lineItem.attributes].map((elm) => {
      return {
        key: elm.key,
        value: elm.value,
      }
    })
    const campaignAttributeIndex = attributes.findIndex((attribute) => {
      return attribute.key === CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY
    })
    let campaignAttribute = attributes[campaignAttributeIndex]

    const isPackProduct = lineItem.attributes.find(
      (item) => item.key == PRODUCT_PACK && item.value == 'true'
    )
    const PACK_KEY_ATTRIBUTE_PREFIX = '_Pack'

    // start update attribute discount calculation
    const discountCalculationAttributeJSON = attributes.find((attribute) => {
      return attribute.key === DISCOUNT_CALCULATION_ATTRIBUTE_KEY
    })
    let discountCalculationValueAttributes = []
    let hasPackInDiscountCalculationValueAttributes = false
    if (discountCalculationAttributeJSON) {
      discountCalculationValueAttributes = JSON.parse(
        discountCalculationAttributeJSON.value
      )
      hasPackInDiscountCalculationValueAttributes =
        discountCalculationValueAttributes.some((item) =>
          item.key?.startsWith(PACK_KEY_ATTRIBUTE_PREFIX)
        )
    }
    let newPackAttributes = []
    let newCampaignAttributes = []
    let newCpPriceAttributes = []
    let newUICartPrice
    let newUICartComparePrice

    const packAttributes = hasPackInDiscountCalculationValueAttributes
      ? discountCalculationValueAttributes.filter((item) =>
          item.key?.startsWith(PACK_KEY_ATTRIBUTE_PREFIX)
        )
      : attributes.filter((item) =>
          item.key?.startsWith(PACK_KEY_ATTRIBUTE_PREFIX)
        )
    if (
      discountStore.campaignDiscountCode?.toLowerCase() ==
        CAMPAIGN_DISCOUNT_BLACKFRIDAY9 &&
      campaignAttribute?.value?.toLowerCase() != CAMPAIGN_DISCOUNT_BLACKFRIDAY9
    ) {
      newPackAttributes = packAttributes.map((item) => {
        return {
          key: item.key,
          value: (
            Number(item.value) *
            ((100 - PERCENTAGE_DISCOUNT_BF9_FOR_PACK_OPTION_PERCENTAGE) / 100)
          ).toString(),
        }
      })

      if (!isPackProduct) {
        const lineItemToProduct = mappingCartLineToProduct(
          JSON.parse(JSON.stringify(lineItem))
        )
        const discountApplied = discountStore.applyDiscount(
          lineItemToProduct,
          lineItemToProduct.priceV2?.amount || 0,
          lineItemToProduct.compareAtPriceV2?.amount || 0,
          lineItemToProduct.sku
        )

        newUICartPrice = {
          key: PRICE_LINE_ITEM_ATTRIBUT_KEY,
          value: discountApplied?.price?.toString(),
        }
        newUICartComparePrice = {
          key: COMPARE_AT_PRICE_LINE_ITEM_ATTRIBUTE_KEY,
          value: discountApplied?.compareAtPrice?.toString(),
        }

        newCpPriceAttributes = [newUICartPrice, newUICartComparePrice]
      }

      newCampaignAttributes.push({
        key: CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY,
        value: discountStore.campaignDiscountCode?.toLowerCase(),
      })
    } else if (
      discountStore.campaignDiscountCode?.toLowerCase() !=
        CAMPAIGN_DISCOUNT_BLACKFRIDAY9 &&
      campaignAttribute?.value?.toLowerCase() == CAMPAIGN_DISCOUNT_BLACKFRIDAY9
    ) {
      newPackAttributes = packAttributes.map((item) => {
        return {
          key: item.key,
          value: (Number(item.value) * 1.25).toString(), // Because BF9 is 20% off the pack, we have to multiply by 125% to get the original price before applying BF9.
        }
      })

      if (!isPackProduct) {
        newUICartPrice = {
          key: PRICE_LINE_ITEM_ATTRIBUT_KEY,
          value: lineItem?.merchandise?.priceV2?.amount || '0',
        }
        newUICartComparePrice = {
          key: COMPARE_AT_PRICE_LINE_ITEM_ATTRIBUTE_KEY,
          value: lineItem?.merchandise?.compareAtPriceV2?.amount || '0',
        }

        newCpPriceAttributes = [newUICartPrice, newUICartComparePrice]
      }

      newCampaignAttributes.push({
        key: CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY,
        value: '',
      })
    }
    if (newPackAttributes.length) {
      discountCalculationValueAttributes =
        discountCalculationValueAttributes.filter(
          (attr) => !attr.key.startsWith(PACK_KEY_ATTRIBUTE_PREFIX)
        )
      discountCalculationValueAttributes = [
        ...discountCalculationValueAttributes,
        ...newPackAttributes,
      ]
    }
    if (newCpPriceAttributes.length) {
      discountCalculationValueAttributes =
        discountCalculationValueAttributes.filter(
          (attr) =>
            !attr.key.startsWith(PRICE_LINE_ITEM_ATTRIBUT_KEY) ||
            !attr.key.startsWith(COMPARE_AT_PRICE_LINE_ITEM_ATTRIBUTE_KEY)
        )
      discountCalculationValueAttributes = [
        ...discountCalculationValueAttributes,
        ...newCpPriceAttributes,
      ]
    }
    if (newCampaignAttributes.length) {
      discountCalculationValueAttributes =
        discountCalculationValueAttributes.filter(
          (attr) =>
            !attr.key.startsWith(CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY)
        )
      discountCalculationValueAttributes = [
        ...discountCalculationValueAttributes,
        ...newCampaignAttributes,
      ]
    }

    const cpPriceAttributeIndex = attributes.findIndex((attribute) => {
      return attribute.key === PRICE_LINE_ITEM_ATTRIBUT_KEY
    })
    if (cpPriceAttributeIndex != -1 && newUICartPrice) {
      attributes.splice(cpPriceAttributeIndex, 1, newUICartPrice)
    } else if (cpPriceAttributeIndex == -1 && newUICartPrice) {
      attributes.push(newUICartPrice)
    }

    const cpComparePriceAttributeIndex = attributes.findIndex((attribute) => {
      return attribute.key === COMPARE_AT_PRICE_LINE_ITEM_ATTRIBUTE_KEY
    })
    if (cpComparePriceAttributeIndex != -1 && newUICartComparePrice) {
      attributes.splice(cpComparePriceAttributeIndex, 1, newUICartComparePrice)
    } else if (cpComparePriceAttributeIndex == -1 && newUICartComparePrice) {
      attributes.push(newUICartComparePrice)
    }

    const discountCalculateAttributeIndex = attributes.findIndex(
      (attribute) => {
        return attribute.key === DISCOUNT_CALCULATION_ATTRIBUTE_KEY
      }
    )
    if (
      discountCalculateAttributeIndex != -1 &&
      discountCalculationValueAttributes.length
    ) {
      attributes.splice(discountCalculateAttributeIndex, 1, {
        key: DISCOUNT_CALCULATION_ATTRIBUTE_KEY,
        value: JSON.stringify(discountCalculationValueAttributes),
      })
    } else if (
      discountCalculateAttributeIndex == -1 &&
      discountCalculationValueAttributes.length
    ) {
      attributes.push({
        key: DISCOUNT_CALCULATION_ATTRIBUTE_KEY,
        value: JSON.stringify(discountCalculationValueAttributes),
      })
    } // end update attribute discount calculation

    // remove the campaign attribute in case can not find any campaign discount code
    if (!discountStore.campaignDiscountCode) {
      if (campaignAttributeIndex == -1) return
      attributes.splice(campaignAttributeIndex, 1)
    }

    // Update the existing or add campaign attribute in case find campaign discount code on the client
    if (campaignAttribute) {
      if (campaignAttribute.value === discountStore.campaignDiscountCode) {
        return
      }
      campaignAttribute.value = discountStore.campaignDiscountCode!
    } else {
      campaignAttribute = {
        key: CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY,
        value: discountStore.campaignDiscountCode!,
      }
      attributes.push(campaignAttribute)
    }

    // push the lineitem to to update items
    lineItemsToUpdate.push({
      id: lineItem.id,
      attributes,
    })
  })

  if (lineItemsToUpdate.length > 0) {
    const timeStamp = getCurrentTimeStamp()
    cartStore.updateCartLines({
      lines: lineItemsToUpdate,
      timeStamp,
    })
  }

  if (
    discountStore.campaignDiscountCode?.toLowerCase() ==
      CAMPAIGN_DISCOUNT_BLACKFRIDAY9 &&
    cartStore.discountCodeCurrentApply
  ) {
    await cartStore.cartDiscountCodeRemove()
  }

  // check for update discount code
  if (discountStore.discountCode) {
    if (!cartStore.discountCodeCurrentApply) {
      const respone = await cartStore.cartDiscountCodeApply(
        discountStore.discountCode
      )
      if (respone?.userErrors?.length && respone.userErrors[0].message) {
        // remove recently discount code
        const isRemove = true
        const domain = `.${routeStore.currentDomain}`
        await saveDiscountCodeToCache(
          discountStore.discountCode,
          domain,
          isRemove
        )
        return
      }
    }
    const domain = `.${routeStore.currentDomain}`
    await saveDiscountCodeToCache(discountStore.discountCode, domain)
    EventBus.trigger(EVENT_SAVE_DISCOUNT_CODE_TO_CACHE)
  }
}

defineExpose({
  checkRefreshCart,
})

const deviceStore = useDeviceStore()
const CartPriceContainer = () => {
  if (!deviceStore.isMobile && props.isFullPage) {
    return h(
      'div',
      { class: 'cart__price-container' },
      h(CartPrice, newProps.value)
    )
  }
  return h(CartPrice, newProps.value)
}
</script>

<style lang="scss">
.cart {
  $S: &;

  @include media-desktop {
    &--full-page {
      display: grid;
      grid-template-columns: 5fr minmax(300px, 2fr);
      grid-template-areas:
        'a b'
        'c c'
        'd d';
      gap: 2em;

      > .divider {
        display: none;
      }
    }
  }
}
.cart__price-container-no-sticky {
  position: unset !important;
}
</style>
